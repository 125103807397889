<div class="user-profile-setting">
  <div class="flex-control">
    <div class="flex-left">
      <div class="p">
        <div class="user-profile-pic thumbnail" [ngStyle]="{'background-image': 'url(' + userService.current_user?.profile_pic + ')'}">
          <div class="loader-pic" *ngIf="loaderService.uploading_in_process">
            <i class="fa fa-spinner fa-spin fs22"></i>
          </div>
          <span class="icon-holder" *ngIf="!loaderService.uploading_in_process" (click)="choose_file()">
            <i class="fa fa-upload fa-2x"></i>
            <h5>Upload Your Picture</h5>
          </span>
        </div>
        <h4 class="fs20 text-secondary mt m0 text-center text-capitalize">{{userService.current_user.full_name}}</h4>
        <h5 class="fs16 m0 mt-sm fw-300 text-center text-capitalize">{{userService.current_user?.roles[0]?.name}}</h5>
      </div>
      <ul class="list-unstyled border-list m0 scroll-browser">
        <li class="list" [class.active]="settingsOf == 'profile'">
          <a (click)="setSettingTab('profile')"><i class="fa icon-user"></i> My Profile</a>
        </li>
        <li class="list" [class.active]="settingsOf == 'preferences'">
          <a (click)="setSettingTab('preferences')"><i class="fa icon-note"></i> User Preferences</a>
        </li>
        <li class="list" [class.active]="settingsOf == 'change-password'">
          <a (click)="setSettingTab('change-password')"><i class="fa icon-key"></i> Change Password</a>
        </li>
        <li class="list">
          <a (click)="userService.logout()"><span class="text-danger"> <i class="fa icon-logout"></i> Log Out</span></a>
        </li>
<!--        <li class="list">-->
<!--          <a (click)="goBack()"><i class="fa fa-backward vl-m ml"></i> Go Back</a>-->
<!--        </li>-->
      </ul>
    </div>
    <div class="flex-body flex-control flex-column">
      <div class="flex-header">
        <h4 class="m0">{{showHeading(settingsOf)}}</h4>
      </div>
      <div class="flex-body scroll-browser">
        <div class="p-lg" *ngIf="settingsOf == 'profile'">
          <ng-container *ngComponentOutlet="userComponent;injector: custom_injector;"></ng-container>
        </div>
        <div class="p-lg" *ngIf="settingsOf == 'preferences'">
          <app-email-configuration [practice]="practice"></app-email-configuration>
          <h4><i class="fa icon-settings vl-m"></i> Clinical Alerts Notifications</h4>
          <div>
            <label>
              <input type="checkbox" [(ngModel)]="allowClinicalAlertEmails" (change)="updatePreference('email', $event)">
              Send notification via eMail
            </label>
          </div>

          <div>
            <label>
              <input type="checkbox" [(ngModel)]="allowClinicalAlertsSms" (change)="updatePreference('sms', $event)">
              Send notification via SMS
            </label>
          </div>
        </div>
        <div class="p-lg" *ngIf="settingsOf == 'change-password'">
          <ng-container *ngComponentOutlet="changePasswordComponent;injector: custom_injector;"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<app-attachments (document_uploaded)="handle_image_upload($event)"
                 (popup_closed)="show_attachment_popup = false" [fileType]="'profile_pic'"
                 [only_modal]="true" [auto_upload]="true" [attachable_id]="userService.current_user.id"
                 [attachable_type]="'User'"
                 [show]="show_attachment_popup">
</app-attachments>

