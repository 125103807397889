import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {LoaderService} from '../../services/loader.service';
import {UserComponent} from '../users/user/user.component';
import {UserService} from '../../services/user.service';
import {Practice} from '../practices/practices/practice';
import {PracticesService} from '../../services/practices.service';
import {ChangePasswordComponent} from '../change-password/change-password.component';
import {AlertService} from '../../_alert';

@Component({
  selector: 'app-user-profile-settings',
  templateUrl: './user-profile-settings.component.html',
  styleUrls: ['./user-profile-settings.component.scss']
})
export class UserProfileSettingsComponent implements OnInit, OnDestroy {
  show_attachment_popup = false;
  settingsOf: any;
  userComponent: any = UserComponent;
  changePasswordComponent: any = ChangePasswordComponent;
  custom_injector: Injector;
  practice: Practice = new Practice();
  allowClinicalAlertEmails = false;
  allowClinicalAlertsSms = false;
  constructor(private route: ActivatedRoute, private location: Location, public loaderService: LoaderService,
              public injector: Injector, public userService: UserService,
              public practiceService: PracticesService, public alertService: AlertService) {}

  ngOnInit() {
    this.userService.showHideSideBar = true;
    this.route.queryParams.subscribe(params => {
      this.settingsOf = params['settingsOf'];
      if (this.settingsOf) {
        this.setSettingTab(this.settingsOf);
      }
    });
    this.setCustomInjector();
  }

  goBack(): void {
    this.location.back();
  }

  handle_image_upload(data) {
    if (data && data.hasOwnProperty('error') === false) {
      this.userService.current_user.profile_pic =  data.profile_pic;
    }
  }
  choose_file() {
    this.show_attachment_popup = true;
    setTimeout( () => {
      this.show_attachment_popup = false;
    }, 75);
  }

  setCustomInjector() {
    this.custom_injector  = Injector.create([
      {provide: 'id', useValue: this.userService.current_user.id},
      {provide: 'profile_type', useValue: this.userService.current_user.profileable_type},
      {provide: 'profileable_id', useValue: this.userService.current_user.profileable_id},
      {provide: 'is_profile_setting_component', useValue: true},
    ], this.injector);
  }

  fetchPractice(id: number) {
    this.loaderService.show()
    this.practiceService.getPractice(id).then(practice => {
      this.practice.load_from_json(practice)
      this.loaderService.hide();
    }).catch(error => {
      console.error('Error fetching practice:', error);
      this.loaderService.hide();
    });
  }

  loadUserPreferences(id): void {
    this.userService.getUserAlertPreferences(id).subscribe((data) => {
      this.allowClinicalAlertEmails = data.allow_clinical_alert_emails;
      this.allowClinicalAlertsSms = data.allow_clinical_alerts_sms;
    });
  }

  updatePreference(type: string, event: any): void {
    this.loaderService.show();
    const updatedPreferences = {
      allow_clinical_alert_emails: this.allowClinicalAlertEmails,
      allow_clinical_alerts_sms: this.allowClinicalAlertsSms
    };
    this.userService.updateUserAlertPreferences(this.userService.current_user.id, updatedPreferences).subscribe(response => {
      this.loaderService.hide();
      this.alertService.success(response.message, this.alertService.options);

      console.log(response.message);
    });


  }

  setSettingTab(settingsOf: any) {
    this.settingsOf = settingsOf;
    if (settingsOf === 'preferences') {
      if (this.userService.current_user.practices_id[0]){
        this.loadUserPreferences(this.userService.current_user.id);
        this.fetchPractice(this.userService.current_user.practices_id[0])
      }
    }
  }

  ngOnDestroy(): void {
    this.userService.showHideSideBar = false;
  }

  showHeading(settingsOf: string): string {
    const headingMap: { [key: string]: string } = {
      'profile': 'My Profile',
      'preferences': 'User Preferences',
      'change-password': 'Change Password'
    };

    return headingMap[settingsOf] || '';
  }
}
