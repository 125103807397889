import {Injectable, OnInit} from '@angular/core';
import {CustomHttpClient} from './custom-http-client.service';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import  {User} from './../routes/users/user'
import { HttpParams } from '@angular/common/http';
import {DataService} from './data.service';
import {Patient} from '../health/doctor/patient';
import {Observable} from 'rxjs/Observable';
import {TabsService} from './tabs.service';
import {HttpTokenService} from './http.token.service';
import {MessageService} from './message.service';


@Injectable()
export class UserService {
  static secureClient: CustomHttpClient;
  patientSignUp = false
  user: User = new User();
  patient_to_verify: Patient;
  public  rerender: Boolean = true;
  show_login_popup: Boolean = false;
  show_signup_popup: Boolean = false;
  public force_close = false;
  public messageService: MessageService;
  public app_component_subscription;
  public hideFooterForPatient = false;
  public iAmPatient = false;
  public user_email_verified_successfully = false;
  public birth_sexes: string[] = ['male', 'female', 'unknown'];
  public sexual_orientations: string[] = ['straight or heterosexual', 'lesbian, gay, or homosexual', 'bisexual', 'don’t know', 'other'];
  public marital_statuses: string[] = ['married', 'widowed', 'separated', 'divorced', 'single', 'Other', 'Decline to answer'];
  public work_statuses: string[] = ['unemployed', 'self employed', 'full-time employee', 'part-time employee', 'temporary or contract employee', 'internship or apprenticeship', 'student', 'other', 'Decline to answer'];
  public responsible_parties: string[] = ['self', 'family member', 'guarantor', 'other'];
  public races: string[] = ['American Indian or Alaska Native', 'Asian', 'Black or African American', 'Native Hawaiian or Other Pacific Islander', 'White', 'Hispanic or Latino', 'Decline to answer'];
  public ethnicities: string[] = ['Hispanic or Latino', 'Not Hispanic or Latino', 'Decline to answer'];
  public preferred_languages = [ { id: 'arabic', name: 'Arabic' }, { id: 'bengali', name: 'Bengali' }, { id: 'english', name: 'English' },
    { id: 'german', name: 'German' }, { id: 'hindi', name: 'Hindi' }, { id: 'japanese', name: 'Japanese' },
    { id: 'mandarin', name: 'Mandarin' }, { id: 'portuguese', name: 'Portuguese' }, { id: 'punjabi', name: 'Punjabi' },
    { id: 'russian', name: 'Russian' }, { id: 'spanish', name: 'spanish' }, { id: 'decline to answer', name: 'Decline to answer' }];
  public showHideSideBar: boolean;

  constructor(private _tokenService: HttpTokenService,
              private httpClient: CustomHttpClient,
              private router: Router,
              private data_service: DataService,
              private tabsService: TabsService) {
    UserService.secureClient = httpClient;
  }

  getAdminUser(practice_id: any): Promise<any> {
    return this.httpClient.get('users/admin_user/' + practice_id).toPromise()
      .then(res => res);
  }

  getUserAlertPreferences(userId: number): Observable<any> {
    return this.httpClient.get('users/alert_preferences/' + userId)
  }


  updateUserAlertPreferences(userId: number, preferences: any): Observable<any> {
    return this.httpClient.put(`users/update_alert_preferences/${userId}`, preferences);
  }

  changeOnlineStatus(status: string) {
    return this.httpClient
      .post('users/change_online_status', {status: status})
      .toPromise()
  }
  get current_user(): User {
    if (this.user.is_loaded() && !this.data_service.dirty_user) {
      return this.user;
    }
    this.data_service.mark_user_fresh();
    if (this._tokenService.currentUserData) {
      this.user.load_from_json(this._tokenService.currentUserData);
      if (this.user.isPatient) {
        let profileable = new Patient().load_from_json(this.user.profileable);
        profileable.user =  this.user;
        this.patient_to_verify = profileable;
      }
      return this.user
    }
    return undefined;
  }

  get permissions() {
    let user_data: any;
    user_data = this._tokenService.currentUserData;
    return  (user_data && user_data.permissions) || []
  }

  permission_for(name, parent, category?, role?) {
    if (!this.permissions) {
      return [];
    }
    return this.permissions.filter(permission => {
      return permission.name.toLowerCase()
          .replace(/[^a-z0-9 ]/g, '') === name.toLowerCase()
        && permission.parent_name.toLowerCase() === parent.toLowerCase()
        && (!category || permission.category_name.toLowerCase() === category.toLowerCase())
        && (!role || permission.role.toLowerCase() === role.toLowerCase())
    })
  }

  is_permitted(name, parent, category?, role?, type?) {
    if (type) {
      return this.current_user.userType.toLowerCase() === type.toLowerCase()
    }
    if (!name) {
      return true;
    }
    let length = this.permission_for(name, parent, category, role);
    return length.length > 0
  }

  permittedCategory(category) {
    let result = this.permissions.filter(permission => {
      return permission.category_name.toLowerCase() == category.toLowerCase()
    })
    return result.length > 0
  }

  load_patient_to_verify(force_load?) {
    if (this.current_user && this.current_user.isPatient && (!this.patient_to_verify || force_load)) {
      let profileable = new Patient().load_from_json(this.current_user.profileable);
      profileable.user =  this.current_user;
      this.patient_to_verify = profileable;
      this.force_close = false
    }
  }

  refresh_patient(patient) {
    this.user.first_name = patient.user.first_name;
    this.user.last_name = patient.user.last_name;
    this.current_user.update_profileable(patient);
    this.load_patient_to_verify(true);
  }

  get user_logged_in() {
    return this._tokenService.currentUserData
  }

  showLogin() {
    this.show_login_popup =  true;
  }
  hideLogin() {
    this.show_login_popup = false;
  }

  toggleLogin() {
    if (this.show_login_popup == true) {
      this.hideLogin();
    } else {
      this.showLogin();
    }
  }


  showSignUp() {
    this.show_signup_popup =  true;
  }
  hideSignUp() {
    this.show_signup_popup = false;
  }
  toggleSignUp() {
    if (this.show_signup_popup == true) {
      this.hideSignUp();
    } else {
      this.showSignUp();
    }
  }

  loginAsEnterpriseUser(practice_id) {
    return this.httpClient.post('users/login_from_enterprise_user',{practice_id: practice_id}).toPromise()
      .then(res => res);
  }

  login(email, password, redirect_to?) {
    return this._tokenService.signIn({
      email:    email,
      password: password,
      login: email
    }).toPromise().then(
      res => {
        this.force_close = false;
        console.log(res);
        if (redirect_to == 'skip') {
          return res;
        }
        if (redirect_to) {
          this.router.navigate(redirect_to)
        } else if (localStorage.getItem('redirectTo')) {
          // logged in so return true
          this.router.navigateByUrl(localStorage.getItem('redirectTo'));
          return true;
        } else {
          this.router.navigate(['/patients', this.current_user.profileable_id]);
        }
        return res
      }, error => {
        console.log('error')
      })
  }

  save_filter(query_string) {
    let args = new HttpParams();
    args = args.set('query_string', query_string);
    return this.httpClient.get('users/save_filter', {params: args})
      .toPromise()
      .then(response => {
        this.current_user.filter = query_string;
      })
  }
  save_telemed_filter(query_string) {
    let args = new HttpParams();
    args = args.set('query_string', query_string);
    return this.httpClient.get('users/save_telemed_filter', {params: args})
      .toPromise()
      .then(response => {
        this.current_user.filter = query_string;
      })
  }
  saveSelectedCalendar(selectedCalendar) {
    return this.httpClient
      .put('users/selected_calendars', {selectedCalendars: selectedCalendar})
      .toPromise()
  }
  getCurrentUserSaveCalendar(): Promise<any> {
    return this.httpClient.get('users/saved_selected_calendars').toPromise()
      .then(res => res);
  }

  logout() {
    if (this.current_user) {
      this._tokenService.signOut().subscribe(
        res =>  {
          this.clearSessionData();
          this.current_user.id = null;
          this.patient_to_verify = null;
          this.router.navigate(['home']);
          localStorage.removeItem('redirectTo')
          localStorage.removeItem('accessToken');
          localStorage.removeItem('client');
          localStorage.removeItem('expiry');
          localStorage.removeItem('tokenType');
          localStorage.removeItem('uid');
          this.tabsService.clearAllTabsOnceForAll();
          localStorage.setItem('refresh', 'true');

        },
        error => {
          console.log(error)
        }
      );
    }
  }
  clearSessionData() {
    this.messageService && this.messageService.resetYourSelf();
    if (this.app_component_subscription) {
      this.app_component_subscription.unsubscribe();
      this._tokenService.already_subscribed_to_userchannel['user_subscription'] = undefined;
      this.app_component_subscription = undefined;
      this._tokenService.logoutSubject.next(true);
    }
    /*    if (this._tokenService.user_subscription) {
          this._tokenService.user_subscription.unsubscribe();
          this._tokenService.cable.unsubscribe();
          this._tokenService.already_subscribed_to_userchannel['user_subscription'] = undefined;
          this._tokenService.user_subscription = undefined;
          this._tokenService.logoutSubject.next(true);
        }*/
  }

  validateToken() {
    console.log('validate token called');
    this._tokenService.validateToken().subscribe(
      res =>      {
        this.rerender = false;
        setTimeout(() => {
          this.rerender = true;
        }, 200)
        if (this.current_user && !this.current_user.isPatient) {
          console.log(localStorage.getItem('redirectTo'));
          this.router.navigateByUrl(localStorage.getItem('redirectTo'));
        }
      },
      error =>    console.log(error)
    );
  }

  set_patient_verified() {
    this.current_user.extra['is_verified'] = true;
  }

  removeUnderScore(str: string) {
    if (str) {
      var i, frags = str.split('_');
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(' ') ? frags.join(' ') : ''  ;
    }
  }

  removeHyphens(str: string) {
    if (str) {
      var i, frags = str.split('-');
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(' ') ? frags.join(' ') : ''  ;
    }
  }

  resendVerificationEmail(id) {
    return this.httpClient.post('users/resend_verification_email', {id: id})
      .toPromise()
      .then(response => response);
  }

  setUserPassword(id: any, new_password: any, passwordConfirmation: any): Promise<any> {
    return this.httpClient.put('users/set_password_and_activate/' + id,
      {id: id, password: new_password, password_confirmation: passwordConfirmation})
      .toPromise()
      .then(response => response);
  }

  capitalize(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1)
  }

  show_verification_banner() {
    return !this.force_close && this.current_user && this.patient_to_verify && !this.patient_to_verify.verified() && this.user_logged_in
  }

  showNumber(type: string, contact_numbers: any[]) {
    const item = contact_numbers.find(item => item['type'] === type);
    return item ? item.value : undefined;
  }
}
@Injectable()
export  class CanActivatePatientProfile implements CanActivate {
  constructor(private userService: UserService, private _router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return true;
    //  if(this.userService.current_user && this.userService.current_user.isPatient && !this.userService.current_user.extra['is_verified']){
    //   this._router.navigate(['/verify-patient']);
    //   return false;
    // }else {
    //    return true
    // }
  }
}
