import {Role} from '../roles/role'
import {Address, ContactNumber} from '../practices/practices/practice'
import {Staff} from '../practices/contacts/contact'
import baseApiUrl from './../../globals'
import {BaseModel} from '../../shared/base';
import {Doctor} from '../practices/doctors/doctor';
import {Location} from '../practices/locations/location';
interface TwilioConfigurations {
  sms: boolean;
  voice: boolean;
  video: boolean;
  twilio_sms_number: any;
}

export class User extends BaseModel {
  id: number;
  owner_id: number;
  chat_privacy: string;
  first_name = '';
  prefix: string;
  suffix: string;
  second_suffix: string;
  last_name: number;
  password = '';
  password_confirmation = '';
  age: number;
  email = '';
  dob = '';
  dob_pet = '';
  public gender: any;
  is_active: boolean;
  username = '';
  profileable: Record<string, any> = {};
  profileable_id = 0;
  profileable_type = '';
  userType: string;
  profileable_name = '';
  staff_profileable_id = 0;
  braintree_customer_id: string;
  success = '';
  online_status: any;
  textclass = '';
  formatted_dob: string;
  role_title = '';
  profile_pic: string = baseApiUrl + '/ilaaj.png';
  profile_pic_id = 0;
  filter: string;
  telemed_filter: string;
  age_gender: string;
  dob_stage = '0';
  dob_stage_pet = '0';
  contact_hashid = '';
  roles_users = [new RolesUser()];
  address: Address = new Address();
  extra: Object = {};
  twilio_configurations: TwilioConfigurations;
  practices_id: any[];
  is_pchf = false;
  selected_calendars  = [];
  contact_numbers: ContactNumber[] = [new ContactNumber('', 'phone'), new ContactNumber('', 'fax')];

  roles: Role[] = [new Role()];
  staffs: Staff[] = [];

  race: string;
  ethnicity: string;
  preferred_language: string[];
  birth_sex: string;
  sexual_orientation: string;
  marital_status: string;
  work_status: string;
  responsible_party: string;
  deceased = false;
  deceased_date: Date;
  email_status: any;
  allow_clinical_alert_emails: boolean;
  allow_clinical_alerts_sms: boolean;


  get full_name() {
    return this.first_name || this.last_name ?  `${this.first_name} ${this.last_name}` : ''
  }

  to_params() {
    return {
      id: this.id,
      first_name: this.first_name,
      last_name: this.last_name,
      password: this.password,
      password_confirmation: this.password_confirmation,
      email: this.email,
      dob: this.dob,
      dob_stage: this.dob_stage,
      gender: this.set_gender(this.gender),
      is_active: this.is_active,
      username: this.username,
      profile_pic_id: this.profile_pic_id,
      profileable_id: this.profileable_id,
      profileable_type: this.profileable_type,
      address_attributes: this.address.to_params(),
      contact_numbers_attributes: this.contact_numbers,
      roles_user_attributes: this.roles_users,
      contact_hashid: this.contact_hashid,
      selected_calendars: this.selected_calendars,
      race: this.race,
      ethnicity: this.ethnicity,
      preferred_language: this.preferred_language,
      birth_sex: this.birth_sex,
      sexual_orientation: this.sexual_orientation,
      marital_status: this.marital_status,
      work_status: this.work_status,
      responsible_party: this.responsible_party,
      deceased: this.deceased,
      deceased_date: this.deceased_date

    }
  }

  set_gender(gender) {
    if (gender === 'male' || gender === 'female') {
      gender = gender === 'male' ? 'male' : 'female';
    } else if (gender === '0') {
      gender = gender === '0' ? '0' : '1';
    }
    this.gender = gender;
    return gender;
  }

  update_profileable(profileable) {
    if (profileable.id === this.profileable_id) {
      this.profileable = profileable;
      this.profileable['mobile_verified?'] = profileable['mobile_verified'];
      this.profileable['email_verified?'] = profileable['email_verified'];
    }
  }

  to_custom_params() {
    return {
      id: this.id,
      first_name: this.first_name,
      last_name: this.last_name,
      email: this.email,
      dob: this.dob,
      gender: this.gender,
      is_active: this.is_active,
      username: this.username,
      profileable_id: this.profileable_id,
      profileable_type: this.profileable_type,
      address_attributes: this.address.to_params(),
      contact_numbers_attributes: this.contact_numbers,
      roles_user_attributes: this.roles_users
    }
  }

  lead_custom_to_params() {
    return {
      id: this.id,
      first_name: this.first_name,
      last_name: this.last_name,
      password: this.password,
      password_confirmation: this.password_confirmation,
      email: this.email,
      dob: this.dob,
      // dob_pet: this.dob_pet,
      dob_stage: this.dob_stage,
      gender: this.set_gender(this.gender),
      is_active: this.is_active,
      username: this.username,
      profile_pic_id: this.profile_pic_id,
      profileable_id: this.profileable_id,
      profileable_type: this.profileable_type,
      address_attributes: this.address.to_params(),
      contact_numbers_attributes: this.contact_numbers
    }
  }

  load_from_json(json) {
    this.twilio_configurations = json.twilio_configurations;
    this.email_status = json.email_status;
    if (json.practices_id) {
      this.practices_id = json.practices_id;
    }
    if (!json) {
      return;
    }
    if (json.profileable) {
      this.prefix = json.profileable.prefix;
      this.profileable = json.profileable;
    }
    this.braintree_customer_id = json.braintree_customer_id;
    this.age_gender = json.age_gender;
    this.owner_id = json.owner_id;
    this.dob_stage = json.dob_stage;
    this.chat_privacy = json.chat_privacy;
    this.online_status = json.online_status;
    this.id = json.id;
    if (json.role_title) {
      this.role_title = json.role_title;
    }
    this.telemed_filter = json.telemed_filter;
    this.filter = json.filter;
    if (json.profile_pic) {
      this.profile_pic = json.profile_pic;
    }
    this.first_name = json.first_name;
    this.last_name =  json.last_name;
    this.email = json.email;
    if (json.valid_email) {
      this.email = json.valid_email
    }

    if(json.dob)
      this.dob = json.dob.split('T')[0]+'T00:00:00';
    this.formatted_dob = json.formatted_dob;
    this.gender = json.gender;
    this.age = json.age;
    this.is_active = json.is_active;
    this.username = json.username;
    this.profileable_id = json.profileable_id;
    this.profileable_type = json.profileable_type;
    this.userType = json.userType;
    this.profileable_name = json.profileable_name;
    this.staff_profileable_id = json.staff_profileable_id;
    this.success = json.success;
    this.textclass = json.textclass;
    this.suffix = json.suffix;
    this.second_suffix = json.second_suffix;
    this.contact_hashid = json.contact_hashid;
    this.race = json.race;
    this.ethnicity = json.ethnicity;
    this.preferred_language = json.preferred_language;
    this.birth_sex = json.birth_sex;
    this.sexual_orientation = json.sexual_orientation;
    this.marital_status = json.marital_status;
    this.work_status = json.work_status;
    this.responsible_party = json.responsible_party;
    this.deceased = json.deceased;
    this.deceased_date = json.deceased_date;
    this.allow_clinical_alert_emails = json.allow_clinical_alert_emails;
    this.allow_clinical_alerts_sms = json.allow_clinical_alerts_sms;
    if (json.selected_calendars_data) {
      this.set_new_calendars(json.selected_calendars_data);
    }
    if (json.extra) {
      this.extra = json.extra;
    }

    if (json.address) {
      this.address.load_from_json(json.address);
    }
    if (json.contact_numbers && json.contact_numbers.length > 0) {
      this.contact_numbers = json.contact_numbers.map(c_number => new ContactNumber('', '').load_from_json(c_number));
    }
    if (json.roles) {
      this.roles = json.roles.map(role => new Role().load_from_json(role));
    }
    if (json.roles_users || json.roles_user) {
      json.roles_user = json.roles_users || json.roles_user;
      this.roles_users = json.roles_user.map(role_user => new RolesUser().load_from_json(role_user));
    }

    if (json.userless_staff) {
      this.staffs = json.userless_staff.map(staff => new Staff().load_from_jason(staff));
    }
    this.is_pchf = json.is_pchf;
    return this;
  }

  is_new_record() {
    return this.id === 0 || this.id === undefined;
  }

  get phone(): any {
    return this.contact_numbers.filter(c => c.type === 'phone')[0];
  }

  get isEnterprise() {
    return this.userType.toLowerCase() === 'enterprise'
  }

  get isPractice() {
    return this.userType.toLowerCase() === 'practice'
  }

  full_name_with_information(age) {
    let name_with_info = this.first_name + ' ' + this.last_name + ', ' + this.gender;
    if (age > 0) {
      name_with_info = name_with_info + '/ ' + age + ' Years, '
    } else {
      name_with_info = name_with_info + ', '
    }
    if (this.phone && this.phone.value > 0) {
      name_with_info = name_with_info + this.phone.value
    }
    return name_with_info;
  }

  public set_new_calendars(selected_calendars_data) {
    this.selected_calendars =  selected_calendars_data.map(calendar_data => ({doctor: new Doctor().load_from_json(calendar_data.doctor), location: new Location().load_from_json(calendar_data.location)}))
  }

  get isDoctor() {
    return this.userType.toLowerCase() === 'doctor'
  }
  get isStaff() {
    return this.userType.toLowerCase() === 'practice'
  }
  get isPracticeUser() {
    return this.userType.toLowerCase() === 'doctor' || this.userType.toLowerCase() === 'practice'
  }

  get isPatient() {
    return this.userType.toLowerCase() === 'patient'
  }

  get isGroup() {
    return this.userType.toLowerCase() === 'group'
  }

  is_loaded() {
    return this.id != null
  }

  isPatientUser() {
    return this.profileable_type.toLowerCase() === 'patient';
  }

  set phone(value: any) {
    let ph = this.contact_numbers.filter(c => c.type.toLowerCase() === 'phone')[0];
    if (ph) {
      this.phone.value = value;
    }
  }
  get fax(): any {
    let fax = this.contact_numbers.filter(c => c.type.toLowerCase() === 'fax')[0];
    if (fax) {
      return fax;
    }
    this.contact_numbers.push(new ContactNumber('', 'fax'));
    return this.contact_numbers.filter(c => c.type.toLowerCase() === 'fax')[0];
  }

  set fax(value: any) {
    this.fax.value = value;
  }
}

export class RolesUser {
  id: number;
  user_id: number;
  role_id = 0;
  role_user_name = '';
  load_from_json(json) {
    this.id = json.id;
    this.user_id = json.user_id;
    this.role_id = json.role_id;
    this.role_user_name = json.role_user_name;
    return this;
  }
}
